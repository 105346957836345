.testimonials-slider {
  .swiper {
    --swiper-pagination-bullet-horizontal-gap: 8px;
    --swiper-pagination-bullet-inactive-color: #fbdbdd;
    --swiper-pagination-bullet-size: 12px;
    --swiper-pagination-color: #f1365b;

    &-pagination {
      position: static;
    }
  }
}

.swiper-pagination-bullet-active {
  background: black;
}

.swiper-pagination-bullet {
  background: black;
}

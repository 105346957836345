.dropdown {

  &-toggle::after {
    content: none;
  }

  &.show .dropdown-toggle *[class^="fa"] {
    transform: rotate(180deg);
  }
}

.img {

  &-avatar {
    border-radius: 50%;
    height: rem(60px);
    overflow: hidden;
    width: rem(60px);

    @include media-breakpoint-up(md) {
      height: rem(66px);
      width: rem(66px);
    }

    @include media-breakpoint-up(lg) {
      height: rem(80px);
      width: rem(80px);
    }

    @include media-breakpoint-up(xxxl) {
      height: rem(92px);
      width: rem(92px);
    }

    &-lg {
      height: rem(80px);
      width: rem(80px);

      @include media-breakpoint-up(md) {
        height: rem(120px);
        width: rem(120px);
      }

      @include media-breakpoint-up(lg) {
        height: rem(160px);
        width: rem(160px);
      }
    }
  }

  &-object-fit-cover {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  &-wrapper {
    align-items: center;
    display: flex;
    justify-content: center;

    &-supplier {
      min-width: rem(52px);
      max-width: rem(52px);

      @include media-breakpoint-up(lg) {
        min-width: rem(44px);
        max-width: rem(44px);
      }

      img {
        max-height: rem(26px);

        @include media-breakpoint-up(lg) {
          max-height: rem(20px);
        }
      }
    }

    &-voucher {
      width: rem(36px);

      @include media-breakpoint-up(lg) {
        width: rem(46px);
      }

      img {
        max-height: rem(18px);

        @include media-breakpoint-up(lg) {
          max-height: rem(23px);
        }
      }
    }

    &-warranty-product {
      overflow: hidden;
      border-radius: $base-border-radius * 0.6; // 6px
      height: rem(36px);
      width: rem(36px);

      @include media-breakpoint-up(lg) {
        border-radius: $base-border-radius * 0.6; // 6px
        height: rem(40px);
        width: rem(40px);
      }

      img {
        object-fit: cover;
      }
    }
  }

  &-third-party-logo {
    max-height: rem(30px);
    max-width: rem(76px);

    @include media-breakpoint-up(md) {
      max-height: rem(34px);
      max-width: rem(84px);
    }

    @include media-breakpoint-up(lg) {
      max-height: rem(38px);
      max-width: rem(94px);
    }

    &-sm {
      max-height: rem(24px);
      max-width: rem(60px);

      @include media-breakpoint-up(md) {
        max-height: rem(27px);
        max-width: rem(68px);
      }

      @include media-breakpoint-up(lg) {
        max-height: rem(30px);
        max-width: rem(76px);
      }
    }

    &-lg {
      max-height: rem(38px);
      max-width: rem(94px);

      @include media-breakpoint-up(md) {
        max-height: rem(42px);
        max-width: rem(105px);
      }

      @include media-breakpoint-up(lg) {
        max-height: rem(47px);
        max-width: rem(118px);
      }
    }
  }

  &-gift-card {
    height: rem(56px);
    object-fit: cover;
    width: rem(90px);

    @include media-breakpoint-up(xxxl) {
      height: rem(67px);
      width: rem(96px);
    }
  }
}

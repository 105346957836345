.hero {
  padding-bottom: $spacer-12;
  padding-top: $spacer-12;

  @include media-breakpoint-up(md) {
    padding-bottom: $spacer-13;
    padding-top: $spacer-13;
  }

  @include media-breakpoint-up(xl) {
    padding-bottom: $spacer * 5.6; // 112px
    padding-top: $spacer * 5.6; // 112px
  }

  @include media-breakpoint-up(xxxl) {
    padding-bottom: $spacer * 6.5; // 130px
    padding-top: $spacer * 6.5; // 130px
  }
}
// reference fonts from src/assets/fonts

@font-face {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: local(''),
    url('../../assets/fonts/gilroy-extrabold.woff2') format('woff2'), // Chrome 26+, Opera 23+, Firefox 39+
    url('../../assets/fonts/gilroy-extrabold.woff') format('woff'); // Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+
}

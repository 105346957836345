.usp-section {
  padding-bottom: $spacer * 17; // 340px

  @include media-breakpoint-up(md) {
    padding-bottom: $spacer * 20.5; // 410px
  }

  @include media-breakpoint-up(xxxl) {
    padding-bottom: $spacer * 24.6; // 492px
  }

  .support-user {
    top: rem(140px);

    @include media-breakpoint-up(md) {
      top: rem(150px);
    }

    @include media-breakpoint-up(xl) {
      top: rem(160px);
    }

    @include media-breakpoint-up(xxxl) {
      top: rem(180px);
    }
  }

  .badge {
    height: rem(44px);
    width: rem(44px);

    &-1 {
      left: rem(22px);
      top: rem(10px);

      @include media-breakpoint-up(lg) {
        left: rem(66px);
      }

      @include media-breakpoint-up(xl) {
        left: rem(128px);
      }

      @include media-breakpoint-up(xxl) {
        left: rem(153px);
      }

      @include media-breakpoint-up(xxxl) {
        left: rem(196px);
        top: rem(12px);
      }
    }

    &-2 {
      left: rem(10px);
      top: rem(130px);

      @include media-breakpoint-up(sm) {
        top: rem(30px);
      }

      @include media-breakpoint-up(md) {
        left: rem(125px);
        top: rem(40px);
      }

      @include media-breakpoint-up(lg) {
        left: rem(195px);
      }

      @include media-breakpoint-up(xl) {
        left: rem(260px);
      }

      @include media-breakpoint-up(xxl) {
        left: rem(313px);
      }

      @include media-breakpoint-up(xxxl) {
        left: rem(400px);
        top: rem(45px);
      }
    }

    &-3 {
      left: rem(40px);
      top: rem(110px);

      @include media-breakpoint-up(md) {
        left: rem(25px);
        top: rem(160px);
      }

      @include media-breakpoint-up(lg) {
        left: rem(58px);
      }

      @include media-breakpoint-up(xl) {
        left: rem(130px);
      }

      @include media-breakpoint-up(xxl) {
        left: rem(158px);
      }

      @include media-breakpoint-up(xxxl) {
        left: rem(200px);
        top: rem(192px);
      }
    }

    &-4 {
      left: rem(25px);
      top: rem(240px);

      @include media-breakpoint-up(sm) {
        left: rem(20px);
        top: rem(220px);
      }

      @include media-breakpoint-up(md) {
        left: rem(170px);
        top: rem(192px);
      }

      @include media-breakpoint-up(lg) {
        left: rem(245px);
        top: rem(182px);
      }

      @include media-breakpoint-up(xl) {
        left: rem(295px);
      }

      @include media-breakpoint-up(xxl) {
        left: rem(355px);
      }

      @include media-breakpoint-up(xxxl) {
        left: rem(453px);
        top: rem(222px);
      }
    }

    &-5 {
      left: rem(105px);
      top: rem(326px);

      @include media-breakpoint-up(md) {
        left: rem(70px);
        top: rem(296px);
      }

      @include media-breakpoint-up(lg) {
        left: rem(141px);
      }

      @include media-breakpoint-up(xl) {
        left: rem(220px);
      }

      @include media-breakpoint-up(xxl) {
        left: rem(263px);
      }

      @include media-breakpoint-up(xxxl) {
        left: rem(336px);
        top: rem(355px);
      }
    }

    &-6 {
      left: rem(190px);
      top: rem(327px);

      @include media-breakpoint-up(lg) {
        left: rem(280px);
      }

      @include media-breakpoint-up(xl) {
        left: rem(362px);
      }

      @include media-breakpoint-up(xxl) {
        left: rem(435px);
      }

      @include media-breakpoint-up(xxxl) {
        left: rem(555px);
        top: rem(392px);
      }
    }

    &-7 {
      left: rem(145px);
      top: rem(325px);

      @include media-breakpoint-up(sm) {
        left: rem(213px);
        top: rem(235px);
      }

      @include media-breakpoint-up(md) {
        left: rem(297px);
      }

      @include media-breakpoint-up(lg) {
        left: rem(395px);
      }

      @include media-breakpoint-up(xl) {
        left: rem(465px);
      }

      @include media-breakpoint-up(xxl) {
        left: rem(560px);
      }

      @include media-breakpoint-up(xxxl) {
        left: rem(714px);
        top: rem(330px);
      }
    }

    &-8 {
      right: rem(40px);
      top: rem(300px);

      @include media-breakpoint-up(sm) {
        right: rem(158px);
        top: rem(330px);
      }

      @include media-breakpoint-up(md) {
        right: rem(330px);
        top: rem(388px);
      }

      @include media-breakpoint-up(lg) {
        right: rem(427px);
      }

      @include media-breakpoint-up(xl) {
        right: rem(517px);
      }

      @include media-breakpoint-up(xxl) {
        right: rem(621px);
      }

      @include media-breakpoint-up(xxxl) {
        right: rem(793px);
        top: rem(465px);
      }
    }

    &-9 {
      right: rem(124px);
      top: rem(210px);

      @include media-breakpoint-up(sm) {
        right: rem(90px);
        top: rem(170px);
      }

      @include media-breakpoint-up(md) {
        right: rem(203px);
        top: rem(224px);
      }

      @include media-breakpoint-up(lg) {
        right: rem(294px);
      }

      @include media-breakpoint-up(xl) {
        right: rem(380px);
      }

      @include media-breakpoint-up(xxl) {
        right: rem(457px);
      }

      @include media-breakpoint-up(xxxl) {
        right: rem(584px);
        top: rem(268px);
      }
    }

    &-10 {
      right: rem(134px);
      top: rem(346px);

      @include media-breakpoint-up(lg) {
        right: rem(210px);
      }

      @include media-breakpoint-up(xl) {
        right: rem(302px);
      }

      @include media-breakpoint-up(xxl) {
        right: rem(363px);
      }

      @include media-breakpoint-up(xxxl) {
        right: rem(463px);
        top: rem(415px);
      }
    }

    &-11 {
      right: rem(30px);
      top: rem(270px);

      @include media-breakpoint-up(md) {
        right: rem(53px);
        top: rem(230px);
      }

      @include media-breakpoint-up(lg) {
        right: rem(126px);
      }

      @include media-breakpoint-up(xl) {
        right: rem(177px);
      }

      @include media-breakpoint-up(xxl) {
        right: rem(213px);
      }

      @include media-breakpoint-up(xxxl) {
        right: rem(272px);
        top: rem(276px);
      }
    }

    &-12 {
      right: rem(122px);
      top: rem(89px);

      @include media-breakpoint-up(lg) {
        right: rem(190px);
      }

      @include media-breakpoint-up(xl) {
        right: rem(254px);
      }

      @include media-breakpoint-up(xxl) {
        right: rem(305px);
      }

      @include media-breakpoint-up(xxxl) {
        right: rem(389px);
        top: rem(106px);
      }
    }

    &-13 {
      right: rem(22px);
      top: rem(160px);

      @include media-breakpoint-up(sm) {
        top: rem(57px);
      }

      @include media-breakpoint-up(md) {
        top: rem(37px);
      }

      @include media-breakpoint-up(lg) {
        right: rem(66px);
      }

      @include media-breakpoint-up(xl) {
        right: rem(128px);
      }

      @include media-breakpoint-up(xxl) {
        right: rem(153px);
      }

      @include media-breakpoint-up(xxxl) {
        right: rem(196px);
        top: rem(44px);
      }
    }
  }
}

.circle-1 {
  height: rem(700px);
  width: rem(700px);

  @include media-breakpoint-up(md) {
    height: rem(840px);
    width: rem(840px);
  }

  @include media-breakpoint-up(xxxl) {
    height: rem(1008px);
    width: rem(1008px);
  }
}

.circle-2 {
  height: rem(533px);
  width: rem(533px);

  @include media-breakpoint-up(md) {
    height: rem(640px);
    width: rem(640px);
  }

  @include media-breakpoint-up(xxxl) {
    height: rem(768px);
    width: rem(768px);
  }
}

.circle-3 {
  height: rem(366px);
  width: rem(366px);

  @include media-breakpoint-up(md) {
    height: rem(440px);
    width: rem(440px);
  }

  @include media-breakpoint-up(xxxl) {
    height: rem(528px);
    width: rem(528px);
  }
}

.usp-section-bg-container {
  z-index: -1;

  &:after {
    background: linear-gradient($body-bg 30%, transparent);
    content: "";
    height: 100%;
    width: 100%;
    z-index: 1;
  }
}
.footer {

  &-logo {
    height: rem(55px);

    @include media-breakpoint-up(md) {
      height: rem(60px);
    }
  }

  .nav-item .nav-link {
    display: inline;
    padding-bottom: 0;
    padding-top: 0;

    &:hover,
    &:focus {
      color: $body-color;
    }

    &.active {
      color: $body-color;
      font-weight: $fw-normal;
    }
  }

  i {
    color: $dark-color;

    &:hover,
    &:focus {
      color: $body-color;
    }
  }
}

.card {

  &-ribbon {
    background-color: $primary-color;
    position: absolute;
    top: 6.5%;
    right: -34%;
    transform: rotate(45deg);
    width: 100%;
  }
}

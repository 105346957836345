.loader {
  border: 10px solid #f3f3f3;
  border-radius: 50%;
  border-top: 10px solid rgb(0, 58, 111);
  width: 3rem;
  height: 3rem;
  animation: spin 2s linear infinite;
}

.loader-main {
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin: auto; */
  height: 75vh;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

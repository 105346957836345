.container,
.container-fluid {
  padding-left: $spacer-4;
  padding-right: $spacer-4;

  @include media-breakpoint-up(lg) {
    padding-left: $spacer-5;
    padding-right: $spacer-5;
  }
}

.fs-14 {
  font-size: 14px;
}

.fs-16 {
  font-size: 16px;
}

.fs-20 {
  font-size: 20px;
}

.fs-48 {
  font-size: 48px;
}

.fs-24 {
  font-size: 24px;
}

.lead-xl {
  font-size: calc(1.28125rem + 0.375vw);
  color: #020617;
}

@media (min-width: 1200px) {
  .lead-xl {
    font-size: 1.5625rem;
    color: #020617;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.text-truncate-2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
  cursor: pointer;
}

.text-truncate-4 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 4;
  cursor: pointer;
  min-height: 7rem;
}

.blogdetails img[title="hello"] {
  width: 100%;
  height: auto;
  margin-bottom: 1.5rem;
}

.blogdetails img {
  margin: 1.5rem 0;
}

.blogdetails img[title="author"] {
  width: 3.125rem;
  height: 3.125rem;
  border-radius: 0.3125rem;
  object-fit: cover;
  position: relative;
  margin-right: 0.75rem;
}

.markdown h3 {
  margin-bottom: 1rem;
  margin-top: 2rem;
}

.mb-10rem {
  margin-bottom: 10rem;
}

.pb-10rem {
  padding-bottom: 10rem !important;
}
